import React from 'react';
import PropTypes, { string } from 'prop-types';
import { Helmet } from 'react-helmet';
import { getLanguageFromField } from '../../../utilities/sharedUtils';
import { urlJoinSafe } from '../../../utilities';

const CategoriesSEO = (props) => {
  const { seo, locale, siteUrl, pageUrl, translations, children } = props;

  // General
  const { title, metaDesc } = seo;

  // Robots
  const { metaRobotsNoindex, metaRobotsNofollow } = seo;

  // Opengraph
  const { opengraphTitle, opengraphDescription, opengraphSiteName } = seo;

  // Static meta tags
  const ogType = 'website';
  const twitterCard = 'summary_large_image';

  const i18nLanguageName = getLanguageFromField('locale', locale).i18nName;

  return (
    <Helmet
      htmlAttributes={{
        lang: i18nLanguageName,
      }}
      meta={[
        { name: 'description', content: metaDesc },
        {
          name: 'robots',
          content: [metaRobotsNoindex, metaRobotsNofollow].join(', '),
        },
        { property: 'og:locale', content: locale },
        // For use later
        // { property: 'og:locale:alternate', content: locale },
        { property: 'og:type', content: ogType },
        { property: 'og:title', content: opengraphTitle },
        { property: 'og:description', content: opengraphDescription },
        { property: 'og:url', content: urlJoinSafe(siteUrl, pageUrl) },
        { property: 'og:site_name', content: opengraphSiteName },
        { name: 'twitter:card', content: twitterCard },
      ]}
    >
      <title>{title}</title>
      <link rel="canonical" href={urlJoinSafe(siteUrl, pageUrl)} />
      <link
        rel="alternate"
        hrefLang={i18nLanguageName}
        href={urlJoinSafe(siteUrl, pageUrl)}
      />
      {translations?.map((translation) => {
        const languageData = getLanguageFromField(
          'locale',
          translation?.language?.locale
        );

        return (
          <link
            key={languageData.i18nName}
            rel="alternate"
            hrefLang={languageData.i18nName}
            href={urlJoinSafe(languageData.languageSiteUrl, translation?.slug)}
          />
        );
      })}
      {children}
    </Helmet>
  );
};

CategoriesSEO.defaultProps = {
  translations: [],
};

CategoriesSEO.propTypes = {
  locale: PropTypes.string.isRequired,
  seo: PropTypes.object.isRequired,
  pageUrl: PropTypes.string.isRequired,
  siteUrl: PropTypes.string.isRequired,
  translations: PropTypes.arrayOf(
    PropTypes.shape({
      language: PropTypes.shape({ locale: PropTypes.string }),
      slug: string,
    })
  ),
};

export default CategoriesSEO;
