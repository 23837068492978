import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import * as style from './Paginator.module.scss';
import { ReactComponent as ChevronLeft } from '../../assets/vectors/Chevron-left.svg';
import { ReactComponent as ChevronRight } from '../../assets/vectors/Chevron-right.svg';
import { pathJoinSafeSlashes } from '../../utilities/pathJoinSafe';

/**
 * Paginator component
 */
const Paginator = (props) => {
  const { categoryLink, currentPage, totalPages } = props;

  // Max pages to be displayed in the Paginator
  const pagesDisplayed = 4;

  // The first number displayed
  const startingPage = Math.max(
    Math.min(totalPages - pagesDisplayed + 1, currentPage - 1),
    1
  );

  // The last number displayed
  const endingPage = Math.min(startingPage + pagesDisplayed, totalPages + 1);

  // Chevron left number
  const previousPage = Math.max(1, currentPage - 1);
  // Chevron right number
  const nextPage = Math.min(totalPages, currentPage + 1);

  // Array of page numbers to be displayed
  const getPageNumbers = () => {
    const pageNumbers = [];

    for (let i = startingPage; i < endingPage; i += 1) {
      pageNumbers.push(i);
    }

    return pageNumbers;
  };

  return (
    <div className={style.paginator}>
      <Link
        to={pathJoinSafeSlashes(categoryLink, previousPage)}
        className={style.paginatorItem}
      >
        <div>
          <ChevronLeft width={20} height={20} />
        </div>
      </Link>
      {getPageNumbers()?.map((pageNumber) => (
        <Link
          to={pathJoinSafeSlashes(categoryLink, pageNumber)}
          className={`${style.paginatorItem} ${
            pageNumber === currentPage ? style.active : ''
          }`}
        >
          <div key={pageNumber}>{pageNumber}</div>
        </Link>
      ))}
      <Link
        to={pathJoinSafeSlashes(categoryLink, nextPage)}
        className={style.paginatorItem}
      >
        <div>
          <ChevronRight width={20} height={20} />
        </div>
      </Link>
    </div>
  );
};

Paginator.propTypes = {
  /** A link to a category page without page number */
  categoryLink: PropTypes.string.isRequired,
  /** An index of the current page (starts from 1) */
  currentPage: PropTypes.number.isRequired,
  /** Total amount of pages */
  totalPages: PropTypes.number.isRequired,
};

export default Paginator;
