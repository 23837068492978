// extracted by mini-css-extract-plugin
export var authorName = "SmallPost-module--authorName--e1107";
export var authorPic = "SmallPost-module--authorPic--f5911";
export var authorSection = "SmallPost-module--authorSection--bca70";
export var category = "SmallPost-module--category--e9438";
export var imgContainer = "SmallPost-module--imgContainer--e92f3";
export var lead = "SmallPost-module--lead--3a1ae";
export var postBody = "SmallPost-module--postBody--f6e09";
export var smallPost = "SmallPost-module--smallPost--a93d2";
export var sustainability = "SmallPost-module--sustainability--654c5";
export var tag = "SmallPost-module--tag--2668a";
export var timeToRead = "SmallPost-module--timeToRead--2891b";
export var title = "SmallPost-module--title--014ab";