import React from 'react';
import { graphql } from 'gatsby';
import { Media } from 'gatsby-plugin-fresnel';

import * as style from './CategoryPage.module.scss';
import MainLayout from '../../components/MainLayout';
import SmallPost from '../../components/SmallPost';
import Paginator from '../../components/Paginator';
import CategoriesSEO from '../../components/SEO/CategoriesSEO';
import { AUTHOR_URL, CATEGORIES_URL } from '../../utilities/urlConstants';
import { pathJoinSafeSlashes } from '../../utilities/pathJoinSafe';
import useOnNewActiveLanguage from '../../hooks/useOnNewActiveLanguage';
import { excludeTagSlugs } from '../../utilities/sharedUtils';

export const query = graphql`
  fragment postData2 on WpPost {
    id
    categories {
      nodes {
        name
        slug
        language {
          locale
        }
      }
    }
    dateGmt
    slug
    title
    author {
      node {
        hq_avatar {
          hqAvatar {
            id
            imgixImage {
              gatsbyImageData(
                srcSetMaxWidth: 256
                placeholder: BLURRED
                sizes: "42px"
              )
            }
          }
        }
        name
        slug
      }
    }
    Lead {
      lead
    }
    language {
      locale
    }
    seo {
      readingTime
    }
  }

  query (
    $id: String!
    $pageSize: Int!
    $offset: Int!
    $topMenuId: String!
    $bottomMenuId: String!
    $footerId: String!
    $socialMenuId: String!
  ) {
    topMenu: wpMenu(id: { eq: $topMenuId }) {
      ...topMenuFields
    }
    bottomMenu: wpMenu(id: { eq: $bottomMenuId }) {
      ...otherMenuFields
    }
    footer: wpMenu(id: { eq: $footerId }) {
      ...otherMenuFields
    }
    socialMenu: wpMenu(id: { eq: $socialMenuId }) {
      ...otherMenuFields
    }
    wpCategory(id: { eq: $id }) {
      name
      slug
      translationsAxios {
        slug
        language {
          locale
        }
      }
      language {
        locale
      }
      seo {
        opengraphTitle
        opengraphDescription
        title
        metaDesc
        opengraphSiteName
        metaRobotsNoindex
        metaRobotsNofollow
      }
    }
    smallPosts: allWpPost(
      sort: { fields: dateGmt, order: DESC }
      filter: {
        status: { eq: "publish" }
        categories: { nodes: { elemMatch: { id: { eq: $id } } } }
      }
      limit: $pageSize
      skip: $offset
    ) {
      nodes {
        ...postData2
        tags {
          nodes {
            slug
          }
        }
        featuredImage {
          node {
            altText
            title
            slug
            imgixImage {
              gatsbyImageData(
                placeholder: BLURRED
                sizes: "(max-width: 768px) 210px, (max-width: 1366px) 260px, 600px"
                imgixParams: { auto: "format", q: 70, usm: 15 }
              )
            }
          }
        }
      }
    }
    site {
      siteMetadata {
        siteUrl
      }
    }
  }
`;

const isDope = process.env.GATSBY_RIDESTORE_ENVIRONMENT === 'dope';

const CategoryPage = (props) => {
  const { data, pageContext } = props;
  const {
    smallPosts: smallPostsRaw,
    wpCategory,
    site,
    topMenu,
    bottomMenu,
    footer,
    socialMenu,
  } = data;
  const { seo } = wpCategory;

  const setNewActiveLanguage = useOnNewActiveLanguage(
    pageContext?.locale,
    wpCategory?.translationsAxios
  );

  const smallPosts = smallPostsRaw?.nodes.filter((post) =>
    excludeTagSlugs
      ? !post.tags.nodes.some((tag) => excludeTagSlugs.includes(tag.slug))
      : post
  );

  const evenSmallPosts = smallPosts?.filter((el, index) => index % 2 === 0);
  const oddSmallPosts = smallPosts?.filter((el, index) => index % 2);

  const formSmallPost = (smallPost) => {
    const featuredImg = smallPost?.featuredImage?.node;
    const category = smallPost?.categories?.nodes?.[0];
    const author = smallPost?.author?.node;

    const categoryLink = isDope
      ? '/'
      : pathJoinSafeSlashes(CATEGORIES_URL, category?.slug);

    return (
      <SmallPost
        key={smallPost?.id}
        imgData={featuredImg?.imgixImage?.gatsbyImageData}
        imgAlt={featuredImg?.altText || featuredImg?.slug}
        imgHoverTitle={featuredImg?.title || featuredImg?.slug}
        category={category?.name}
        categoryLink={categoryLink}
        title={smallPost?.title}
        authorName={author?.name}
        authorSlug={pathJoinSafeSlashes(AUTHOR_URL, author?.slug)}
        authorImgData={author?.hq_avatar?.hqAvatar?.imgixImage?.gatsbyImageData}
        datePostedGmt={smallPost?.dateGmt}
        slug={pathJoinSafeSlashes(smallPost?.slug)}
        timeToRead={smallPost?.seo?.readingTime}
      />
    );
  };

  return (
    <MainLayout
      setNewActiveLanguage={setNewActiveLanguage}
      topMenu={topMenu}
      bottomMenu={bottomMenu}
      footer={footer}
      socialMenu={socialMenu}
    >
      <CategoriesSEO
        locale={wpCategory?.language?.locale}
        seo={seo}
        pageUrl={pageContext?.pageUrl}
        siteUrl={site?.siteMetadata?.siteUrl}
        translations={wpCategory?.translationsAxios}
      />
      <div className={style.pageWrapper}>
        <h2 className={style.categoryHeading}>{wpCategory?.name}</h2>
        <Media lessThan="tablet">
          <div className={style.postDeck}>
            {smallPosts?.map((smallPost) => formSmallPost(smallPost))}
          </div>
        </Media>
        <Media greaterThanOrEqual="tablet">
          <div className={style.postDeckDesktop}>
            <div
              className={`${style.postDeckColumn} ${style.postDeckColumnLeft}`}
            >
              {evenSmallPosts?.map((smallPost) => formSmallPost(smallPost))}
            </div>
            <div className={style.postDeckSeparator}>
              <div className={style.postDeckSeparatorInner} />
            </div>
            <div
              className={`${style.postDeckColumn} ${style.postDeckColumnRight}`}
            >
              {oddSmallPosts?.map((smallPost) => formSmallPost(smallPost))}
            </div>
          </div>
        </Media>
        {pageContext?.totalPages > 1 && (
          <Paginator
            currentPage={pageContext?.currentPage}
            totalPages={pageContext?.totalPages}
            categoryLink={pathJoinSafeSlashes(CATEGORIES_URL, wpCategory?.slug)}
          />
        )}
      </div>
    </MainLayout>
  );
};

CategoryPage.propTypes = {};

export default CategoryPage;
